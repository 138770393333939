<template>
  <form class="signup-form" @submit.prevent="signup">
    <div>Username</div>
    <ion-input
      v-model.trim="user.username"
      :color="validatorColor(usernameOk)"
      class="c-input mt-1"
      placeholder="Username"
      autocapitalize="off"
      maxlength="15"
      @ionInput="usernameOk = true"
    />

    <div v-if="!usernameOk" class="error-msg">
      <span v-if="!user.username">Username is required</span>
      <span v-if="usernameExists">Username is in use</span>
      <span v-if="user.username && !usernameExists">Invalid username. Allowed special characters: ._-</span>
    </div>

    <div class="mt-4">Email</div>
    <ion-input
      v-model.trim="user.email"
      :color="validatorColor(emailOk)"
      class="c-input mt-1"
      placeholder="Email"
      autocapitalize="off"
      maxlength="254"
      @ionInput="emailOk = true"
    />

    <div v-if="!emailOk" class="error-msg">
      <span v-if="!user.email">Email is required</span>
      <span v-else>Invalid E-mail, or already exists</span>
    </div>

    <div class="mt-4">Password</div>
    <ion-password-input
      v-model="user.password"
      :color="validatorColor(pwOk)"
      class="c-input pw-input mt-1"
      placeholder="Password (8+ characters)"
      autocapitalize="off"
      type="password"
      @ionInput="pwOk = true"
    />

    <div v-if="!pwOk" class="error-msg">
      <span v-if="!user.password">Password is required</span>
      <span v-else>Password must be 8+ characters long</span>
    </div>
    <ion-custom-button :loading="loading" class="signup-button mx-auto" type="submit" v-if="!isModal">
      Sign up
    </ion-custom-button>
    <input type="submit" hidden />
  </form>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import logger from '@/shared/services/logger';
import { toast } from '@/shared/native';
import { passwordIsValid, usernameIsValid } from '@/shared/utils/auth';
import IonPasswordInput from '@/shared/components/ion-password-input.vue';
import IonCustomButton from '@/shared/components/ion-custom-button.vue';
import { validateEmail } from '@/shared/utils/string';
import { authStore } from '@/shared/pinia-store/auth';
import { useReCaptcha } from 'vue-recaptcha-v3';

@Options({
  name: 'SignupForm',
  components: {
    IonPasswordInput,
    IonCustomButton,
  },
})
export default class SignupForm extends Vue {
  @Prop({ default: false }) isModal!: Boolean;
  @Prop({ default: false }) isClicked!: Boolean;

  public user = {
    username: '',
    email: '',
    first_name: '',
    password: '',
  };
  public recaptchaInstance = useReCaptcha();
  public pwOk = true;
  public emailOk = true;
  public usernameOk = true;
  public usernameExists = false;
  public loading = false;
  public userData: any = {};
  openFirstLoginModal = false;

  public validatorColor(value: boolean) {
    return !value ? 'danger' : '';
  }

  public get formValid() {
    const { pwOk, usernameOk, emailOk, user } = this;
    const { username, email, password } = user;
    return !!(emailOk && pwOk && usernameOk && username && email && password);
  }

  public async mounted() {
    await this.recaptchaInstance?.recaptchaLoaded();
  }
  public async handleError(e: any) {
    this.isModal ? this.$emit('loading', (this.loading = false)) : (this.loading = false);
    let message = 'Error while registering, please try again later.';
    if (e && e.response) {
      if (e.response.data && e.response.data.password) {
        message = e.response.data.password[0];
      }
      if (e.response.data.email) {
        message = 'Email is invalid or in use';
        this.$emit('error');
      }
      if (e.response.data.username) {
        message = 'Username is invalid or in use';
        this.$emit('error');
      }
      if (e.response.data.non_field_errors) {
        e.response.data.non_field_errors.forEach((err: any) => {
          if (err.includes('Email')) message = 'Email is invalid or in use';
        });
        this.$emit('error');
        return;
      }
    }
    await toast.show(message, 'nonative', 'danger');
    const userDetails = {
      username: this.user.username || 'n/a',
      passwordLength: this.user.password.length,
      email: this.user.email,
    };
    logger.error({
      e,
      loc: 'pages.signup:signup',
      msg: 'Error registering with email',
      userDetails,
    } as any);
    this.$emit('error');
  }
  public async signup() {
    const router = useRouter();
    if (this.loading) return;
    this.isModal ? this.$emit('loading', (this.loading = true)) : (this.loading = true);

    await this.checkUsername();
    await this.checkEmail();
    await this.checkPassword1();
    if (!this.formValid) {
      this.isModal ? this.$emit('loading', (this.loading = false)) : (this.loading = false);
      this.$emit('error');
      return;
    }

    try {
      // const token = await this.recaptchaInstance?.executeRecaptcha('signup');
      const { register } = authStore();
      this.isModal ? await register({ ...this.user, token: '1' }, true) : await register({ ...this.user, token: '1' });
      this.isModal ? this.$emit('registered') : router.replace('/onboarding');
    } catch (e) {
      this.isModal ? this.$emit('loading', (this.loading = false)) : (this.loading = false);
      this.$emit('error');
      await this.handleError(e);
    }
  }

  public closeloginFirstLoginModal() {
    this.openFirstLoginModal = false;
  }

  public checkPassword1() {
    if (!this.user.password) {
      this.pwOk = false;
      this.$emit('error');
      return;
    }
    this.pwOk = passwordIsValid(this.user.password);
  }

  public async checkEmail() {
    if (!this.user.email) {
      this.emailOk = false;
      this.$emit('error');
      return;
    }
    if (!validateEmail(this.user.email)) {
      this.emailOk = false;
      this.$emit('error');
    } else {
      try {
        // const emailExists = await checkEmailExists(this.user.email);
        // this.emailOk = !emailExists;
      } catch (e) {
        logger.error({
          e,
          loc: 'pages.signup:checkEmail',
          msg: 'Unknown error, likely dropped connection',
        } as any);
        this.$emit('error');
      }
    }
  }

  public async checkUsername() {
    if (!this.user.username) {
      this.usernameOk = false;
      this.$emit('error');
      return;
    }

    this.usernameExists = false;

    if (!this.user.username || !usernameIsValid(this.user.username)) {
      this.usernameOk = false;
      this.$emit('error');
      return;
    }

    try {
      // const exists = await checkUsernameExists(this.user.username);
      // this.usernameExists = exists;
      // this.usernameOk = !exists;
    } catch (e) {
      logger.error({
        e,
        loc: 'pages.signup:checkUsername',
        msg: 'Unknown error, likely dropped connection',
      } as any);
    }
  }

  @Watch('isClicked', { immediate: true })
  public action() {
    if (this.isClicked) {
      this.signup();
    }
  }
}
</script>
<style lang="sass" scoped>
.signup-form
  width: 100%
  .signup-button
    max-width: 150px
  .error-msg
    margin-top: 3px
    margin-left: 10px
    font-size: 12px
    color: var(--ion-color-danger)
  .c-input
    &:not(:first-of-type)
      margin-top: 16px
  .pw-input, .signup-button
    margin-top: 16px
</style>
